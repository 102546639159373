import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  padding: 190px 100px 0 100px;

  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 30px 0 30px;
  }

  @media (max-width: 768px) {
    padding: 110px 20px 0 20px;
  }

  .my-masonry-grid {
    display: flex;
    margin-left: -90px; /* gutter size offset */
    width: auto;

    @media (max-width: 1300px) {
      margin-left: -60px; /* gutter size offset */
    }
  }
  .my-masonry-grid_column {
    padding-left: 90px; /* gutter size */
    background-clip: padding-box;

    @media (max-width: 1300px) {
      padding-left: 60px; /* gutter size */
    }
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    margin-bottom: 190px;

    @media (max-width: 1200px) {
      margin-bottom: 120px;
    }

    @media (max-width: 900px) {
      margin-bottom: 60px;
    }
  }
`;

const Name = styled.div`
  width: 100%;
  margin: 0 0 50px 0;
  text-align: center;

  & h1 {
    font-size: 24px;
    line-height: 151%;
    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
  }

  & button {
    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
    font-size: 12px;
    line-height: normal;
    font-variant: all-small-caps;
    letter-spacing: 0.48px;

    margin: 0 auto;
    padding: 5px 10px;
  }
`;

const Biography = styled.div`
  padding: 60px 20px;
  margin: 0 auto;

  max-width: 616px;

  & p {
    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 1.4px;
    text-align: left;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & a.contact-agent {
    display: block;
    text-align: right;
    margin: 3em 0 0 0;

    font-size: 14px;
    line-height: 135%;
    letter-spacing: 0.7px;

    cursor: pointer;
  }
`;

const PhotographerContainer = styled.div`
  margin-bottom: 90px;

  @media (max-width: 768px) {
    margin-bottom: 45px;
  }

  & img.portrait {
    max-height: 600px;
    object-fit: contain;

    padding: 0px 30px;
    width: calc(100% - 60px);
  }

  & .details {
    text-align: center;

    & h2 {
      margin: 20px 0 0 0;

      font-size: 14px;
      line-height: 130%;
      letter-spacing: 0.56px;
    }
  }
`;

const PhotographerThumbnail = styled.div`
  width: 100%;
  margin: 200px 0 0 0;

  & img {
    border-radius: 100%;

    width: 200px;
    height: 200px;
    margin: 0 auto;

    object-fit: cover;
  }
`;

const MasonryContainer = styled.div`
  position: relative;
  width: 100%;

  .my-masonry-grid {
    display: flex;
    margin-left: -90px;
    width: auto;

    @media (max-width: 1000px) {
      margin-left: -60px;
    }
  }
  .my-masonry-grid_column {
    padding-left: 90px;
    background-clip: padding-box;

    @media (max-width: 1000px) {
      padding-left: 60px;
    }
  }
`;

const PhotographerContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("photographer");
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Macy = require("macy");

      const macyInstance = Macy({
        container: `#masonry`,
        columns: 2,
        margin: 90,
        breakAt: {
          1400: 2,
          767: 1,
        },
      });
    }
  }, []);

  const content = data.prismicPhotographer.data.projects.map(
    (content, index) => {
      if (content.project.document === null) return null;

      return (
        <PhotographerContainer key={content.project.document.id}>
          <Link
            to={content.project.document.url}
            state={{
              from: data.prismicPhotographer.url,
              section: "photographer",
            }}
          >
            {content.project.document.data.vimeo_mp4_file.url !== "" ? (
              <video
                src={content.project.document.data.vimeo_mp4_file.url}
                muted
                loop
                autoPlay
                playsInline
                preload="metadata"
              />
            ) : (
              <>
                {content.project.document.data.image_thumbnail.fluid !==
                  null && (
                  <img
                    className={ImageOrientation(
                      content.project.document.data.image_thumbnail
                    )}
                    srcSet={
                      content.project.document.data.image_thumbnail.fluid
                        .srcSetWebp
                    }
                    src={
                      content.project.document.data.image_thumbnail.fluid
                        .srcWebp
                    }
                    alt={content.project.document.data.image_thumbnail.alt}
                    // loading="lazy"
                  />
                )}
              </>
            )}
          </Link>
          <div className="details">
            <h2 className="caslon-doric">
              {content.project.document.data.title.text}
            </h2>
          </div>
        </PhotographerContainer>
      );
    }
  );

  return (
    <>
      <Helmet
        title={`${data.prismicPhotographer.data.name.text} – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `${data.prismicPhotographer.data.name.text} – Park Village`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicPhotographer.data.name.text} – Park Village`,
          },
        ]}
      />

      <Container>
        <Name>
          <div
            className="caslon-doric"
            dangerouslySetInnerHTML={{
              __html: data.prismicPhotographer.data.name.html,
            }}
          />
          <button
            className="caslon-doric"
            onClick={() =>
              document.querySelector("#biography").scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          >
            Bio
          </button>
        </Name>

        <MasonryContainer id="masonry">{content}</MasonryContainer>

        {data.prismicPhotographer.data.thumbnail.fluid !== null && (
          <PhotographerThumbnail>
            <img
              srcSet={data.prismicPhotographer.data.thumbnail.fluid.srcSetWebp}
              src={data.prismicPhotographer.data.thumbnail.fluid.srcWebp}
              alt={data.prismicPhotographer.data.thumbnail.alt}
              loading="lazy"
            />
          </PhotographerThumbnail>
        )}

        <Biography id="biography">
          <div
            className="caslon-doric"
            dangerouslySetInnerHTML={{
              __html: data.prismicPhotographer.data.bio.html,
            }}
          />

          {data.prismicPhotographer.data.contact_agent !== null && (
            <>
              {data.prismicPhotographer.data.contact_agent.url !== "" && (
                <a
                  className="contact-agent caslon-doric"
                  href={data.prismicPhotographer.data.contact_agent.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact agent
                </a>
              )}
            </>
          )}
        </Biography>
      </Container>
    </>
  );
};

const Photographer = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <PhotographerContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export default withPreview(Photographer);

export const query = graphql`
  query Photographer($uid: String!) {
    prismicPhotographer(uid: { eq: $uid }) {
      url
      data {
        name {
          html
          text
        }
        bio {
          html
        }
        thumbnail {
          alt
          fluid {
            srcWebp
            srcSetWebp
          }
        }
        contact_agent {
          url
        }
        projects {
          project {
            document {
              ... on PrismicPhotographyProject {
                id
                url
                data {
                  title {
                    text
                  }
                  image_thumbnail {
                    fluid(
                      imgixParams: { auto: "format" }
                      srcSetBreakpoints: [200, 340, 520, 800]
                    ) {
                      srcWebp
                      srcSetWebp
                    }
                    dimensions {
                      width
                      height
                    }
                    alt
                  }
                  vimeo_mp4_file {
                    url
                  }
                  body {
                    ... on PrismicPhotographyProjectBodyImage {
                      id
                    }
                    ... on PrismicPhotographyProjectBodyVideo {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
