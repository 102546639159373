import React, { useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Somponents
import { Map } from "../components/map/map";

const Container = styled.div`
  width: 100%;
  max-width: 1400px;

  padding: 190px 100px 0 100px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 50px 0 50px;
  }

  @media (max-width: 768px) {
    padding: 100px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  max-width: 920px;

  margin: 0 auto;
`;

const Row = styled.div`
  width: 100%;

  margin: 0 auto 60px auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    margin: 0 auto 40px auto;
  }
`;

const Title = styled.div`
  width: 100%;
  max-width: 690px;

  margin: 0 auto;

  & h1 {
    font-size: 36px;
    line-height: 48px;

    letter-spacing: 0.01em;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 26px;
      line-height: 30px;
    }
  }
`;

const Text = styled.div`
  width: 100%;
  max-width: 690px;

  margin: 0 auto;

  & h1,
  & h2,
  & h3,
  & p,
  & strong,
  & em {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.04em;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & strong {
    font-weight: bold;
  }

  & em {
    font-family: "Caslon Doric Web Italic", "Helvetica", "Lucida Grande",
      sans-serif;
  }

  & a {
    text-decoration: underline;
    transition: 250ms color ease;

    &:hover {
      text-decoration: none;
      color: #df3f1c;
    }
  }

  & p:first-of-type {
    margin-top: 0;
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  & p:empty {
    display: inline-block;
    margin: 0;
  }

  & h1 {
    margin: 1em 0;
  }
`;

const Image = styled.div`
  margin: 0;

  & img {
    margin: 0 auto;

    &.large-image {
      max-width: 800px;

      &.portrait {
        max-width: 500px;
      }
    }

    &.small-image {
      max-width: 470px;
    }
  }

  & .image-caption {
    margin: 0 auto;
    text-align: center;

    & p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.04em;
    }
  }
`;

const Video = styled.div`
  margin: 0;

  &.large-video {
    max-width: 800px;
  }

  &.small-video {
    max-width: 470px;
  }

  & .image-caption {
    margin: 0 auto;
    text-align: center;

    & p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.04em;
    }
  }
`;

// const Container = styled.div`
//   width: 100%;
//   max-width: 1400px;

//   padding: 190px 100px 0 100px;
//   margin: 0 auto;

//   @media (max-width: 1200px) {
//     padding: 190px 50px 0 50px;
//   }

//   @media (max-width: 768px) {
//     padding: 100px 0 0 0;
//   }
// `;

// const ContentContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;

//   width: 100%;
//   max-width: 920px;

//   margin: 0 auto;

//   & img {
//     width: 100%;
//     max-width: 500px;
//     margin: 0 auto;
//   }
// `;

// const Row = styled.div`
//   width: 100%;
// `;

// const Text = styled.div`
//   width: 100%;
//   max-width: 800px;

//   margin: 0 auto;
//   padding: 20px 0;

//   text-align: center;

//   & h1,
//   & h2,
//   & h3,
//   & p {
//     font-family: "LDNBloomsburyOldStyle-Book", Times, "Times New Roman", serif;

//     font-size: 32px;
//     line-height: 115%;
//     letter-spacing: 0.01em;
//   }

//   & h1 {
//     margin: 1em 0;
//   }

//   @media (max-width: 1300px) {
//     & h1,
//     & h2,
//     & h3,
//     & p {
//       font-size: 28px;
//       line-height: 36px;
//     }
//   }

//   @media (max-width: 768px) {
//     padding: 0 20px;

//     & h1,
//     & h2,
//     & h3,
//     & p {
//       font-size: 24px;
//       line-height: 27px;
//     }
//   }
// `;

const AwardsContainer = styled.div`
  max-width: 700px;

  margin: 50px auto;

  @media (max-width: 768px) {
    margin: 65px auto;
  }

  & h1 {
    margin: 0;
  }
`;

const SingleAward = styled.div`
  margin: 1em 0 0 0;
  padding: 0 20px;

  width: 100%;
  text-align: center;

  & h2 {
    font-size: 24px;
    line-height: 27px;

    font-family: "LDNBloomsburyOldStyle-Book", Times, "Times New Roman", serif;
  }

  & p {
    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.04em;

    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
  }
`;

const TextContainer = styled.div`
  margin: 50px auto;

  & h1 {
    margin: 0;
  }

  & .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    margin: 0 -25px;

    // @media (max-width: 820px) {
    //   margin: 0 auto;

    //   margin: 1em 0 0 0;
    // }

    & .item {
      flex: 50%;
      max-width: 50%;

      padding: 0 25px;
      margin: 0 0 1em 0;

      @media (max-width: 820px) {
        flex: 100%;
        max-width: 100%;
      }

      // & h2 {
      //   font-size: 24px;
      //   line-height: 27px;

      //   font-family: "LDNBloomsburyOldStyle-Book", Times, "Times New Roman",
      //     serif;
      // }

      // & p {
      //   font-size: 15px;
      //   line-height: 130%;
      //   letter-spacing: 0.04em;

      //   font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande",
      //     sans-serif;
      // }
    }
  }
`;

const MapContainer = styled.div`
  margin: 50px auto;
`;

const MapText = styled.div`
  width: 100%;
  text-align: center;

  padding: 40px 0;

  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;

  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.04em;

  @media (max-width: 1080px) {
    padding: 20px 0;
  }
`;

const PageContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("page");
  }, []);

  const content = data.prismicPage.data.body1.map((content, index) => {
    if (content.slice_type === "subtitle") {
      return (
        <Row key={content.id}>
          <Title
            dangerouslySetInnerHTML={{
              __html: content.primary.subtitle.html,
            }}
          />
        </Row>
      );
    }

    if (content.slice_type === "text") {
      return (
        <Row key={content.id}>
          <Text
            className="caslon-doric"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </Row>
      );
    }

    if (content.slice_type === "two_column_text") {
      const textItems = content.items.map((text, index) => (
        <div key={`text${index}`} className="item">
          <div
            className="caslon-doric"
            dangerouslySetInnerHTML={{
              __html: text.text_title.html,
            }}
          />
          <div
            className="caslon-doric"
            dangerouslySetInnerHTML={{
              __html: text.text.html,
            }}
          />
        </div>
      ));

      return (
        <Row key={content.id}>
          <TextContainer>
            <Text
              className="caslon-doric"
              dangerouslySetInnerHTML={{
                __html: content.primary.section_title.html,
              }}
            />

            <div className="columns">{textItems}</div>
          </TextContainer>
        </Row>
      );
    }

    if (content.slice_type === "awards") {
      const awardItems = content.items.map((award, index) => (
        <SingleAward key={`award_${index}`}>
          <div
            className="caslon-doric"
            dangerouslySetInnerHTML={{
              __html: award.award_title.html,
            }}
          />
          <div
            className="caslon-doric"
            dangerouslySetInnerHTML={{
              __html: award.award__text.html,
            }}
          />
        </SingleAward>
      ));

      return (
        <Row key={content.id}>
          <AwardsContainer>
            <Text
              className="caslon-doric"
              dangerouslySetInnerHTML={{
                __html: content.primary.section_title.html,
              }}
            />
            {awardItems}
          </AwardsContainer>
        </Row>
      );
    }

    if (content.slice_type === "image") {
      return (
        <Row key={content.id}>
          {content.primary.image.fluid !== null && (
            <img
              srcSet={content.primary.image.fluid.srcSetWebp}
              src={content.primary.image.fluid.srcWebp}
              alt={content.primary.image.alt}
              loading="lazy"
            />
          )}
        </Row>
      );
    }

    if (content.slice_type === "video") {
      return (
        <Row key={content.id}>
          <div
            dangerouslySetInnerHTML={{
              __html: content.primary.video.html,
            }}
          />
        </Row>
      );
    }

    if (content.slice_type === "map") {
      return (
        <Row key={content.id}>
          <MapContainer>
            <Map
              latitude={content.primary.address.latitude}
              longitude={content.primary.address.longitude}
            />
            <MapText
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
          </MapContainer>
        </Row>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicPage.data.title.text} – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `${data.prismicPage.data.title.text} – Park Village`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicPage.data.title.text} – Park Village`,
          },
        ]}
      />

      <Container>
        {data.prismicPage.data.hero_image.fluid !== null && (
          <img
            srcSet={data.prismicPage.data.hero_image.fluid.srcSetWebp}
            src={data.prismicPage.data.hero_image.fluid.srcWebp}
            alt={data.prismicPage.data.hero_image.alt}
            loading="lazy"
          />
        )}
        <ContentContainer>{content}</ContentContainer>
      </Container>
    </>
  );
};

const Page = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => <PageContent setPageType={setPageType} data={data} />}
  </PageTypeConsumer>
);

export default withPreview(Page);

export const query = graphql`
  query Page($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      prismicId
      data {
        title {
          html
          text
        }
        hero_image {
          fluid {
            srcSetWebp
            srcWebp
          }
        }
        body1 {
          __typename
          ... on PrismicPageBody1Subtitle {
            id
            slice_type
            primary {
              subtitle {
                html
              }
            }
          }
          ... on PrismicPageBody1Text {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPageBody1Image {
            id
            slice_type
            primary {
              image {
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
          ... on PrismicPageBody1Map {
            id
            slice_type
            primary {
              address {
                latitude
                longitude
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBody1Video {
            id
            slice_type
            primary {
              video {
                html
              }
            }
          }
          ... on PrismicPageBody1Awards {
            id
            slice_type
            primary {
              section_title {
                html
              }
            }
            items {
              award_title {
                html
              }
              award__text {
                html
              }
            }
          }
          ... on PrismicPageBody1TwoColumnText {
            id
            slice_type
            primary {
              section_title {
                html
              }
            }
            items {
              text_title {
                html
              }
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
