import * as React from "react";
import { withUnpublishedPreview } from "gatsby-source-prismic";

// Pages
import Contact from "../pages/contact";
import Events from "../pages/events";
import Experiential from "../pages/experiential";
import FoodAndDrink from "../pages/food-and-drink";
import Index from "../pages/index";
import WhoWeAre from "./who-we-are";
import Production from "../pages/film";
import Stills from "../pages/photography";
import Stories from "../pages/stories";
import Studios from "../pages/studios";
import Directors from "./directors";
import WhatWeDo from "../pages/what-we-do";
import TheVillage from "../pages/the-village";

// Templates
import Article from "../templates/article";
import CustomReel from "../templates/custom-reel";
import Director from "../templates/director";
import Page from "../templates/page";
import Photographer from "../templates/photographer";
import PhotographyProject from "../templates/photography-project";
import SingleVideo from "../templates/single-video";
import TextPage from "../templates/text-page";

import PreviewPage from "../pages/preview";

const NotFoundPage = () => (
  <div>
    <h1>Page not found!</h1>
  </div>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(PreviewPage, {
  templateMap: {
    community: TheVillage,
    contact: Contact,
    directors: Production,
    events: Events,
    experiential: Experiential,
    foodAndDrink: FoodAndDrink,
    homepage: Index,
    mission: WhoWeAre,
    photographers: Stills,
    photography_project: PhotographyProject,
    stories: Stories,
    studios: Studios,
    the_talent: Directors,
    what_we_do: WhatWeDo,
    article: Article,
    custom_reel: CustomReel,
    director: Director,
    page: Page,
    photographer: Photographer,
    video: SingleVideo,
    text_page: TextPage,
  },
});
